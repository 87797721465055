'use client';
import { useEffect, useRef } from 'react';

import { t } from 'i18next';
import { useForm } from 'react-hook-form';

import { passwordChecker } from '@/helpers/validations';
import { PasswordType } from '@/types/profile';

import Input from '../../Input';

type Props = {
  isShow: boolean;
  onClose: VoidFunction;
  onSubmit: (param: PasswordType) => void;
};

function PasswordResetContent({ isShow, onSubmit }: Props) {
  const {
    register,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset();
  }, [isShow, reset]);

  const password = useRef({});
  password.current = watch('password', '');

  return (
    <div className={isShow?'block':'hidden'}>
      <h1 className=' font-bold text-lg flex justify-center mb-3'> Change Password.</h1>
      <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col'>
        <Input
          label='Current Password'
          name='current'
          type='password'
          placeholder='Password'
          register={register('current', { required: 'Please enter a current password' })}
          error={errors.current}
          className='mb-4'
        />
        <Input
          label='New Password'
          name='password'
          type='password'
          placeholder='Password'
          register={register('password', {
            required: 'Please enter a new password',
            validate: (value) =>
              passwordChecker(value) ||
              'Password must have at least 8 characters, a number, a mix of uppercase/lowercase, and at least 1 special character (!@#$%^&*)',
          })}
          error={errors.password}
          className='mb-2'
        />
        <Input
          label='Confirm Password'
          name='confirm'
          type='password'
          placeholder='Confirm password'
          register={register('confirm', {
            required: 'Please confirm your password',
            validate: (value) => value === password.current || 'Passwords do not match',
          })}
          error={errors.confirm}
        />
        <div className='flex gap-2 justify-center mt-11'>
          <button
            className="flex justify-center w-full md:w-[40%] items-center go-btn text-white p-2.5 rounded-[30px]"
            type='submit'
          >
            <p className='text-[16px]'>{t('Reset')}</p>
          </button>
        </div>
      </form>
    </div>
  );
}

export default PasswordResetContent;
