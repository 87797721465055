import i18n from '@/app/i18n-configs';
i18n.init();

const strongPasswordRegExp = new RegExp(
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
);

const emailRegexp = new RegExp(
  /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
export const requiredValidation = i18n.t('Please fill this field');

export const nameValidation = {
  required: i18n.t('Please enter your first name'),
  minLength: {
    value: 2,
    message: i18n.t('Name must have at least 2 characters'),
  },
};

export const surnameValidation = {
  required: i18n.t('Please enter your last name'),
  minLength: {
    value: 2,
    message: i18n.t('Surname must have at least 2 characters'),
  },
};

export const recommendationValidation = {
  required: i18n.t('How did you hear about us?'),
};

export const emailValidation = {
  required: i18n.t('Email Address is required'),
};

export const passwordChecker = (pass: string): boolean =>
  !!strongPasswordRegExp.test(pass);

export const emailChecker = (email: string): boolean => emailRegexp.test(email);

export const isJson = (value: string) => {
  try {
    JSON.parse(value);
    return true;
  } catch (e) {
    return false;
  }
};
