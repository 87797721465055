'use client';

import { useEffect, useState } from 'react';

import { hasCookie, setCookie } from 'cookies-next';

import FunctionBtn from '..//FunctionalBtn';

const CookieConsent = () => {
  const [showConsent, setShowConsent] = useState(true);

  useEffect(() => {
    setShowConsent(hasCookie('termsConsent'));
  }, []);

  const acceptCookie = () => {
    setShowConsent(true);
    setCookie('termsConsent', 'true');
  };

  if (showConsent) {
    return null;
  }

  return (
    <div className="fixed bottom-5 sm:left-5 flex items-center justify-between py-2 px-6 rounded-full bg-data-access-row1 min-w-[400px] w-full sm:w-4/5 md:w-2/5">
      <span className="inline-block text-white mr-8 text-small italic font-light">
      This site uses cookies to provide you with a great user experience. By using ChainQ, you accept our <a
          target="_blank" className="underline text-red-500" href="https://www.btcs.com/legal/">Terms of Use</a>
      </span>
      <FunctionBtn classes={{ text: 'text-sm p-1' }} onClick={acceptCookie}>
        ACCEPT ALL &gt;
      </FunctionBtn>
    </div>
  );
};

export default CookieConsent;
