'use client';

import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Yes() {
  return (
    <>
      <FontAwesomeIcon icon={faCheck} className="fa-solid text-blue-25"/>&nbsp;
      <span>YES</span>
    </>
  );
}
