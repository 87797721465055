'use client';
import React, { Fragment } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

import IconButton from '../Button/icon-button';

type ModalSizes = 'sm' | 'md' | 'xl' | 'xxl' | '3/4' | 'screen-md' | 'screen-80' | 'full';

interface IModalProps {
  isOpen: boolean;
  close: () => void;
  size?: ModalSizes;
  children: React.ReactNode;
  showOverflow?: boolean;
}

const Modal: React.FC<IModalProps> = ({
  isOpen,
  close,
  size = 'screen-80',
  children,
  showOverflow = false,
}) => {
  return (
    <Transition.Root show={isOpen} as={Fragment} >
      <Dialog
        as='div'
        static
        className='fixed inset-0 overflow-y-auto z-[100]'
        open={isOpen}
        onClose={close}
      >
        <div className={classNames(
          'flex items-center md:items-end justify-center min-h-screen text-center sm:block sm:p-0 fixed inset-0',
          { 'pt-4 px-4 pb-20': size !== 'full' },
          { 'p-2 h-full': size === 'full' },
        )}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75' />
          </Transition.Child>
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div
              className={classNames(`w-full max-w-${size} inline-block align-bottom bg-white dark:bg-dark rounded-lg pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full sm:p-6 relative tutorial m-auto`, {
                'overflow-visible': showOverflow,
                'overflow-hidden': !showOverflow,
                'pt-5 pb-4': size !== 'full',
                'pt-4 h-full': size === 'full'
              })}
            >
              <div className='absolute top-0 right-0 z-10 w-full pt-4 pr-4 rounded-lg'>
                <IconButton
                  type='button'
                  className='float-right text-gray-400 hover:text-gray-800'
                  onClick={close}
                >
                  <span className='sr-only'>Close</span>
                  <XMarkIcon className='w-6 h-6' aria-hidden='true' />
                </IconButton>
              </div>
              <div className={classNames('h-full max-h-screen-90 px-4 mt-4 dark:text-white', {
                'overflow-y-visible': showOverflow,
                'overflow-y-auto': !showOverflow,
                'max-h-screen-80': size !== 'full'
              })}>
                {children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
