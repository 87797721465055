'use client';
import Button from '../Button';
import Modal from '../Modal';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  message?: string;
};

const QuestionModal = ({ isOpen, onClose, onSuccess, message }: Props) => {
  return (
    <Modal isOpen={isOpen} close={onClose} size='sm'>
      <div className='flex flex-col items-center py-4'>
        <p className='mb-8 text-lg font-bold dark:text-white'>{message}</p>
        <div className='grid w-full grid-cols-2 gap-x-4'>
          <Button label='No' className='bg-[#726b6b]' variant='outline' onClick={onClose} />
          <Button label='Yes' className='bg-btcs-pink' onClick={onSuccess} />
        </div>
      </div>
    </Modal>
  );
};

export default QuestionModal;
