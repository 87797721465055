'use client';

import React, { MouseEvent, useCallback } from 'react';

import classnames from 'classnames';

type Props = {
  label: React.ReactNode;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  variant?: 'text' | 'outline' | 'primary';
};

const Button = ({
  onClick,
  type = 'button',
  variant = 'primary',
  label,
  className = '',
  disabled = false,
}: Props) => {
  let activeClasses: string;
  if (disabled) activeClasses = 'bg-gray-300 cursor-default text-gray-500';
  else if (variant === 'primary')
    activeClasses = classnames('shadow-sm text-white ', {
      'bg-pink-ee hover:bg-pink-d6': !className.includes('bg-' && 'hover:bg-'),
    });
  else if (variant === 'text') activeClasses = 'text-pink-ee';
  else
    activeClasses =
      'shadow-sm text-pink-ee dark:text-white bg-white hover:bg-white-fa border-pink-ee dark:border-gray-300 dark:bg-dark-light';

  const classes = classnames(
    activeClasses,
    className,
    'w-full flex justify-center items-center border border-transparent rounded-md font-medium focus:outline-none',
    { 'py-2 px-4': !className.includes('py') },
    { 'text-sm': !className.includes('text-') }
  );

  const clickHandler = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (disabled) {
        e.stopPropagation();
        e.preventDefault();
      } else {
        if (onClick) {
          onClick(e);
        }
      }
    },
    [onClick, disabled]
  );

  return (
    <button onClick={clickHandler} type={type} className={classes}>
      {label}
    </button>
  );
};

export default Button;
