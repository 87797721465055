export const QAs = [
  {
    q: 'What is ChainQ?',
    a: 'ChainQ is an AI-powered blockchain analytics platform, making it easy to analyze the complexities of blockchain data. With its intuitive interface and powerful features, including cutting-edge AI, personalized search functionality, and insightful visualizations, ChainQ empowers users of all levels to explore, organize and understand blockchain data.',
  },
  {
    q: 'What blockchains does ChainQ support?',
    a: 'Currently, ChainQ only provides data on the Cosmos (ATOM) blockchain. We welcome feedback and suggestions for additional features and blockchains to support on the ChainQ platform. Please reach out to us via the chatbot or email us at chainq@btcs.com.',
  },
  {
    q: 'What kind of questions can I ask ChainQ AI Analytics?',
    a: 'ChainQ AI Analytics have been designed to answer simple analytics questions, see few examples below. Keep in mind, more complex questions require longer processing time, especially for queries involving averages, total counts or sum of values. In most cases, limiting your questions to a specific time-frame (i.e. last month, between November 2023 and March 2024, etc.) greatly reduces the time needed to answer your question.\n\n' +
      '\'Which transaction has the highest fee in USD?\',\n\n' +
      '\'What were the daily average transaction fees in last 10 days for each day?\',\n\n' +
      '\'How many transactions were made on 1/1/2024?\',\n\n' +
      '\'What is the total transaction amount in ATOM on 1/1/2024?\',\n\n' +
      '\'What is the total of all transaction fees in USD in January 2024?\',\n\n' +
      '\'Show me transactions greater than one million USD.\',\n\n' +
      '\'What was the average ATOM/USD rate on 1/1/2024?\',\n\n' +
      '\'How many transactions were sent from cosmos17vg7d0hcapc9v9enp3yaz22a0mssfvnvqn6cfy?\',\n\n' +
      '\'What is the total number of blocks recorded?\',\n\n' +
      '\'How many transactions were received by cosmos17vg7d0hcapc9v9enp3yaz22a0mssfvnvqn6cfy?\',\n\n' +
      '\'What is the total transaction amount in USD for all transactions on 1/1/2024?\''
  },
  {
    q: 'What is ChainQ Custom Search?',
    a: 'ChainQ Custom Search allows you to use a simple web form to search for any specific transactions using the following options:\n\n' +
      '• transaction type\n' +
      '• transaction start or end dates\n' +
      '• FROM or TO wallet addresses, or both\n' +
      '• USD or ATOM maximum or minimum amounts\n' +
      '• transaction hash\n' +
      '• transaction memo',
  },
  {
    q: 'How are USD values calculated?',
    a: 'For transactions prior to February 2024 USD values (amount, fee) are calculated using the closing price as reported by CoinMarketCap for the specific date. Starting February 2024 USD Values are calculated using real time CoinMarketCap pricing when new data is added into ChainQ which is approximately every hour.',
  },
  {
    q: 'What Cosmos transaction types are available in ChainQ?',
    a: 'Cosmos blockchain: Send/Receive, Rewards, Delegations, Undelegations and Redelegations',
  },
  {
    q: 'What are My Bookmarks?',
    a: 'The My Bookmarks feature allows users to select specific transactions and save them to a personalized account for future reference. My Bookmarks are only available to PRO and BUSINESS subscribers.',
  },
  {
    q: 'What are My Searches?',
    a: 'The My Searches feature allows users to save a specific custom search query and save it to your account for future reference. My Searches are only available to PRO and BUSINESS subscribers.',
  },
  {
    q: 'Does ChainQ provide real-time data?',
    a: 'ChainQ data is updated approximately once every hour',
  },
  {
    q: 'Does ChainQ provide data visualization?',
    a: 'ChainQ AI Analytics results are automatically displayed on a chart. You can adjust X or Y axis, type of a chart or download the chart as an PNG image.',
  },
  {
    q: 'Can I download my query results?',
    a: 'Yes, to download your query results you must be subscribed to the PRO or BUSINESS account.',
  },
  {
    q: 'What format can I download my query results?',
    a: 'Query results are available to be exported to CSV by clicking the Download CSV button.',
  },
  {
    q: 'Can I get query results via an API?',
    a: 'Currently, we do not offer any API access. If you are interested in a customized API, please email chainq@btcs.com',
  },
  {
    q: 'Is ChainQ free to start?',
    a: 'Yes, ChainQ offers a free Starter account to everyone. However, the account limits query results to 500 records at a time and is limited to the last 6 months of data.',
  },
  {
    q: 'What if I want to download more than 500 records?',
    a: 'The PRO subscription allows to download 5,000 records at a time and the BUSINESS subscription has 50,000 records at a time limit.',
  },
  {
    q: ' What if I want to download more than 50,000 records?',
    a: 'If you have a need to download more than 50,000 records at a time, please reach out to us via the chatbot or email us at chainq@btcs.com.',
  },
  {
    q: 'What if I’m having a problem with the platform?',
    a: 'If you are having issues with our platform, we encourage you to start a conversation with our team via the chatbot or email us at chainq@btcs.com.',
  },
  {
    q: 'How much historical data does ChainQ provide?',
    a: 'ChainQ currently provides Cosmos 4th (cosmoshub-4) version blockchain data starting 2/18/2021 for PRO and BUSINESS users. STARTER level users only have access to the last 6 months of Cosmos data.',
  },
];
