'use client';

export default function Footer() {

  return (
    <div className="flex flex-row text-[13px] justify-end text-white min-h-[8vh]">
      <div className=" cursor-pointer mr-2"> &#169; {new Date().getFullYear()} BTCS inc. All Rights Reserved.</div>
      <div className="underline">
        <a href="https://btcs.com/legal" target="_blank">Legal & Privacy</a>
      </div>
      &nbsp;&nbsp;
      <div className="underline">
        <a href="mailto:chainq@btcs.com" target="_blank">Contact Us</a>
      </div>
    </div>
  );
}
