'use client';

import { useEffect, useState } from 'react';

import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ModalBody, ModalContent, ModalHeader } from '@nextui-org/react';
import { useRouter } from 'next/navigation';

import CommonModal from '@/app/components/CommonModal';
import { displayDate } from '@/helpers';
import { deleteSearch, getSavedSearch } from '@/services/search';
import { SearchType } from '@/types/subscription';

import ResultModal from '../ResultModal';

type Props = {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
}

type TSavedSearchData = {
  _id: string;
  name: string;
  created_at: string;
  search_query: string;
  sql_query: string;
  updated_at: string;
  type: string;
  user: string;
}

export default function SavedSearchModal({ isOpen, setOpen }: Props) {
  const [data, setData] = useState<TSavedSearchData[]>([]);
  const [deletingId, setDeletingId] = useState<string>('');
  const [announceModal, setAnnounceModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const router = useRouter();
  useEffect(() => {
    if(isOpen){
      setLoading(true);
      getSavedSearch()
        .then((res) => {
          setData(res.data);
          setLoading(false);
          if(res.data.length == 0) {
            setAnnounceModal(true);
            setOpen(false);
          }
        });
    }

  },[isOpen, setOpen]);

  const handleDelete = (search_id: string) => {
    setDeletingId(search_id);
    deleteSearch(search_id)
      .then((res) => {
        if(res.status==204){
          setData(data.filter(item => item._id !== search_id));
        }
      })
      .finally(()=> {
        setDeletingId('');
      });
  };

  const handleSearch = (query: string, type: string) => {
    setOpen(false);
    if (type === SearchType.AI_SEARCH) {
      router.push(`/ai-analytics/${query.split(' ').join('-')}`);
    } else {
      router.push('/custom-search?' + query);
    }
  };
  
  return (
    announceModal ? 
      <ResultModal 
        message='You have no searches saved.'
        onSuccess={()=> setAnnounceModal(false)}
        isOpen = {announceModal}
      /> :
      <CommonModal onESC={() => setOpen(false)} isOpen={isOpen} closeButton={<></>} size='2xl'>
        <ModalContent>
          <ModalHeader>
            <p>My Searches:</p>
            <div
              className='ml-auto text-gray-400 bg-transparent hover:cursor-pointer hover:bg-slate-100 dark:hover:bg-slate-700 rounded-lg w-8 h-8 inline-flex justify-center items-center'
              onClick={() => setOpen(false)}
            >
              <svg className='w-3 h-3' aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 14 14'>
                <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6' />
              </svg>
            </div>
          </ModalHeader>
          <ModalBody className='mb-20'>
            {
              loading ?
                <div className='flex items-center justify-center'>
                  {/* eslint-disable-next-line @next/next/no-img-element */}
                  <img className=' h-[50px] w-[50px] mx-4' alt="loading..." src='/loading.gif'></img>
                </div> 
                : <>
            
                  {data.map((item: TSavedSearchData, idx) =>  
                    <div className='flex justify-between' key={idx}>
                      <div>
                        <p className=' font-bold'>{item.name}</p>
                        <p>Saved {displayDate(item.created_at)}</p>
                      </div>
                      <div className='flex items-center'>
                        <button className=' bg-slate-600 text-white rounded-[30px] mr-2 px-4 py-2 min-w-[100px]' onClick={()=>handleDelete(item._id)}>
                          {
                            deletingId == item._id ? 
                            // eslint-disable-next-line @next/next/no-img-element
                              <img className='h-[20px] mx-4' alt="loading..." src='/loading.gif'></img>  : <>
                        Delete
                                <FontAwesomeIcon icon={faTrashCan} className="ml-2 inline-block pr-2" />
                              </>
                          }
                        </button>
                        <button
                          className="flex justify-center w-full md:w-[40%] items-center go-btn text-white px-8 rounded-[30px] py-2"
                          onClick={() => handleSearch(item.search_query, item.type)}
                        >
                          <p className='text-[16px]'>Search &gt;</p>
                        </button>
                      </div>
                    </div>
                  )}
                </>
            }
          </ModalBody>
        </ModalContent>
      </CommonModal>
  );
}