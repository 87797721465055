import { Capacitor, registerPlugin } from '@capacitor/core';
import { Http } from '@capacitor-community/http';

interface CookiePlugin {
  setCookie(options: { url: string; key: string; value: string }): any;
}

const CookieManagerPlugin = registerPlugin<CookiePlugin>('CookieManagerPlugin');

const url = () => {
  return window.location.origin;
};

export const setCookie = async (key: string, value: string) => {
  if (Capacitor.getPlatform() === 'ios') {
    return CookieManagerPlugin.setCookie({
      url: window.location.host,
      key,
      value,
    });
  }

  return Http.setCookie({
    url: url(),
    key,
    value,
  });
};

export const getCookie = async (key: string) => {
  const ret = await Http.getCookies({
    url: url(),
  });

  return ret?.cookies?.find((cookie) => key === cookie.key)?.value;
};

export const deleteCookie = (key: string) => {
  return Http.deleteCookie({
    url: url(),
    key,
  });
};
