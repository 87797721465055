import { useState, useEffect } from 'react';

import { getWindowDimensions } from '@/helpers';

export default function useMobileView() {
  const { width } = getWindowDimensions();
  const mobileWidth = parseInt(process.env.REACT_APP_MOBILE_WIDTH ?? '768');
  const [isMobileView, setIsMobileView] = useState(width < mobileWidth);

  useEffect(() => {
    function handleResize() {
      const { width } = getWindowDimensions();
      setIsMobileView(width < 768);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobileView;
}
