import React, { createContext, useContext, useState } from 'react';

type FilterModalProps = {
  filterModal: boolean;
  setFilterModal: (accountModal: boolean) => void
}

export const FilterModalContext = createContext<FilterModalProps>({
  filterModal: false,
  setFilterModal: () => null,
});
export const useFilterModal = () => useContext(FilterModalContext);

export const FilterModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [filterModal, setFilterModal] = useState(false);

  return (
    <FilterModalContext.Provider value={{ filterModal, setFilterModal }}>
      {children}
    </FilterModalContext.Provider>
  );
};