'use client';

import React, { ChangeEvent, ReactNode, useCallback } from 'react';

import classnames from 'classnames';
import { FieldError } from 'react-hook-form';

type Props = {
  name: string;
  label: ReactNode | string;
  disabled?: boolean;
  className?: string;
  labelClassName?: string;
  checked?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  register: any;
  error?: FieldError;
};

const Checkbox = ({
  onChange,
  label,
  name,
  className = '',
  labelClassName = '',
  disabled = false,
  checked = false,
  register = {},
  error,
}: Props) => {
  const classes = classnames(
    className,
    'h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded',
    {
      'bg-gray-300': disabled,
    }
  );

  const changeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (disabled) {
        e.stopPropagation();
        e.preventDefault();
      } else {
        if (onChange) {
          onChange(e);
        }
      }
    },
    [onChange, disabled]
  );

  return (
    <div>
      <label
        htmlFor={name}
        className='flex items-center space-x-2 text-sm text-white md:text-gray-900'
      >
        <input
          onChange={changeHandler}
          id={name}
          name={name}
          type='checkbox'
          disabled={disabled}
          defaultChecked={checked}
          className={classes}
          {...register}
        />
        <span className={labelClassName}>{label}</span>
      </label>
      {error ? (
        <div className='flex-1 mt-1 ml-6'>
          <p className='mt-1 text-xs text-red-500'>{error.message}</p>
        </div>
      ) : null}
    </div>
  );
};

export default Checkbox;
