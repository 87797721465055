'use client';
import { useTheme } from 'next-themes';

export default function Brand() {
  const { theme, setTheme } = useTheme();
  return (
    <div className=" mb-10">
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <a href="/">
        <img className="h-20 mb-0" src={ theme == 'dark' || !theme? '/chainq-beta-white.png' : '/chainq-beta.png'} alt="logo-img"></img>
      </a>
    </div>
  );
}
