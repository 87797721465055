import { configureStore } from '@reduxjs/toolkit';

import { userApi } from '@/services/profile';

import loadingReducer from './loading';
import profileReducer from './profile';
import recordReducer from './record';
import userPlanReducer from './user-plan';

const store = configureStore({
  reducer: {
    [userApi.reducerPath]: userApi.reducer,
    loading: loadingReducer,
    records: recordReducer,
    profile: profileReducer,
    user_plan: userPlanReducer
  },
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware().concat([
      userApi.middleware
    ])
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
