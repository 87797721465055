'use client';

import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

import { MenuBtn } from './MenuBtn';

type ItemType = {
  label: string;
  value: string;
  icon: IconDefinition;
}
type SubMenuProps = {
  setMenuContent: React.Dispatch<React.SetStateAction<any>>
  items:ItemType[]
  selected: string
}

export const SubMenu = ({ setMenuContent,  items, selected } : SubMenuProps): React.ReactNode => {
  return (
    <>
      <div className="transition-transform" aria-label="Sidebar">
        <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
          <ul>
            {items.map((item, idx) => <li key={idx}  className='min-w-[200px]'>
              <MenuBtn active={ selected==item.value } label={item.label} onClick={() => setMenuContent(item.value)} icon={item.icon} />
            </li>)}
          </ul>
        </div>
      </div>
    </>
  );
};