import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { updateUserPlan } from './actions';

const PREFIX = 'user-plan';

const initialState = {
  plan: ''
};

export const userPlanSlice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {
    setUserPlan(state, action: PayloadAction<string>){
      state.plan = action.payload;
    }
  }
});

const {
  setUserPlan
} = userPlanSlice.actions;

export const updateUserPlanFn = (thunkApi: any, plan: string) => {
  thunkApi.dispatch(setUserPlan(plan));
} ;

export { updateUserPlan };

export default userPlanSlice.reducer;