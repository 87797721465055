'use client';

import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  onClick: ()=> void;
  label: string;
  icon: IconDefinition
  active?: boolean
}
export const MenuBtn = ({ onClick, label, icon, active }: Props): React.ReactNode => {
  return (
    <>
      <a href='#' 
        className={`flex items-center p-2 text-gray-900 
            rounded-lg dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700 group 
            ${active ? 'dark:bg-gray-700 bg-gray-200' : '' }`}
        onClick={onClick}
      >
        <FontAwesomeIcon icon={icon} className="fa-solid lg:w-[1.3rem] w-[1rem] inline-block pr-2"/>
        <span className='ml-3'>{label}</span>
      </a>
    </>
  );
};