'use client';

import { useCallback, useState } from 'react';

import { ModalBody, ModalContent } from '@nextui-org/react';
import Compressor from 'compressorjs';
import { useSession } from 'next-auth/react';
import Slider from 'react-customizable-slider';
import Cropper from 'react-easy-crop';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CommonModal from '@/app/components/CommonModal';
import { cropperSize, precision } from '@/app/constants';
import { TEXT_CONTENTS } from '@/app/constants';
import getCroppedImg from '@/helpers/image-crop';
import { nameValidation, surnameValidation } from '@/helpers/validations';
import { useGetUserDataQuery } from '@/services/profile';
import { IProfileData, IProfileFormData, cropArea, cropSize } from '@/types/profile';

import Button from '../../Button';
import Checkbox from '../../Checkbox';
import Input from '../../Input';

import Avatar from './Avatar';

import { ModalState } from '.';

type Props = {
  setModalState: (action: ModalState) => void;
  openResetModal: () => void;
  updateAvatar: (value: FormData) => void;
  updateUserData: (value: IProfileData) => void;
};

function ProfileForm({ setModalState, openResetModal, updateAvatar, updateUserData }: Props){
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    mode: 'onChange',
  });

  const { t } = useTranslation();
  const { data: profileData } = useGetUserDataQuery('');
  const { data: session } = useSession();

  const [previewImageURL, setPreviewImageURL] = useState<string>('');
  const [crop, setCrop] = useState<cropSize>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(10);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<cropArea>({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  });
  const [isCropModalOpen, setIsCropModalOpen] = useState<boolean>(false);
  const [minZoom, setMinZoom] = useState<number>(10);

  const getNormalizedFile = (file: File) => {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        maxWidth: 1000,
        maxHeight: 1000,
        success(normalizedFile) {
          resolve(normalizedFile);
        },
        error(error) {
          reject(error);
        },
      });
    });
  };

  const readFile = useCallback((file: File) => {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        getNormalizedFile(file)
          .then((normalizedFile) => reader.readAsDataURL(normalizedFile as Blob))
          .catch((error) => reject(error));
      } catch (error) {
        reject(error);
      }
    });
  }, []);

  const photoUpload = async (e: any) => {
    e.preventDefault();
    const file = e.target.files[0];
    const img = new Image();
    if (file) {
      const objectUrl = (await readFile(file)) as string;
      img.onload = function () {
        setTimeout(() => {
          const containerWidth =
            document.getElementsByClassName('reactEasyCrop_Container')[0].clientWidth;
          const containerHeight =
            document.getElementsByClassName('reactEasyCrop_Container')[0].clientHeight;
          const ratio =
            img.width > img.height
              ? (cropperSize * img.width) / containerWidth / img.height
              : (cropperSize * img.height) / containerHeight / img.width;
          setZoom(ratio * precision);
          setMinZoom(Math.floor(ratio * precision));
        }, 300);
      };
      img.src = objectUrl;
      setPreviewImageURL(objectUrl);
      setIsCropModalOpen(true);
    }
  };

  const onSubmit = async (data: IProfileFormData) => {
    updateUserData({ ...data, _id: session?.user?._id, crypto: [] });
  };

  const onCropComplete = useCallback((_cropArea: any, croppedAreaPix: cropArea) => {
    setCroppedAreaPixels(croppedAreaPix);
  }, []);

  const showCroppedImage = useCallback(async () => {
    setModalState({ action: 'avatar', isOpen: false });
    const croppedImage = await getCroppedImg(previewImageURL, croppedAreaPixels);
    const data = new FormData();
    data.append('file', croppedImage as Blob);
    updateAvatar(data);
    setIsCropModalOpen(false);
  }, [croppedAreaPixels, previewImageURL, setModalState, updateAvatar]);

  return (
    <div>
      <CommonModal isOpen={isCropModalOpen} size='xl' onClose={() => setIsCropModalOpen(false)}>
        <ModalContent>
          <ModalBody>
            <Cropper
              image={previewImageURL}
              crop={crop}
              rotation={0}
              zoom={zoom / precision}
              aspect={4 / 3}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              cropShape='round'
              cropSize={{ width: cropperSize, height: cropperSize }}
              classes={{ containerClassName: 'bg-transparent h-80' }}
              style={{
                containerStyle: { position: 'relative' },
                mediaStyle: { position: 'static' },
              }}
              objectFit={'contain'}
            />
            <div className='w-full py-4 space-y-2'>
              <div className='w-full'>
                <Slider
                  max={minZoom * 10}
                  min={minZoom}
                  value={zoom}
                  onChange={setZoom}
                  renderTrack={() => <div className='w-full h-1 bg-gray-200' />}
                  renderThumb={() => (
                    <div className='w-4 h-4 bg-blue-900 rounded-full cursor-pointer' />
                  )}
                />
              </div>
              <Button className='bg-btcs-pink' label={t('Save')} onClick={showCroppedImage}/>
            </div>
          </ModalBody>
        </ModalContent>
      </CommonModal>
      <Avatar
        onChange={photoUpload}
        onDelete={() =>
          setModalState({
            action: 'avatar',
            type: 'question',
            isOpen: true,
            message: TEXT_CONTENTS.question.avatar,
          })
        }
        name={
          profileData
            ? profileData?.name?.toUpperCase()[0] + profileData?.surname?.toUpperCase()[0]
            : ''
        }
        src={profileData?.photoURL as string}
        className='mb-4 md:col-span-2'
      />
      {profileData && <form 
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='flex flex-col space-y-4 md:grid md:grid-cols-2 md:space-y-0 md:gap-2 mb-3'>
          <div>
            <p className='font-bold'>First Name</p>
            <Input
              name='name'
              type='text'
              placeholder={t('First Name')}
              register={{
                defaultValue: profileData.name,
                ...register('name', {
                  ...nameValidation,
                  validate: (val) => {
                    if (!val.trim()) {
                      return t('Please enter your first name') as string;
                    }
                    return true;
                  },
                }),
                placeholder: t('First Name'),
              }}
              error={errors.name}
            />
          </div>
          <div>
            <p className='font-bold'>Last Name</p>
            <Input
              name='surname'
              type='text'
              placeholder={t('Last Name')}
              register={{
                ...register('surname', {
                  ...surnameValidation,
                  validate: (val) => {
                    if (!val.trim()) {
                      return t('Please enter your Last name') as string;
                    }
                    return true;
                  },
                }),
                defaultValue: profileData.surname,
                placeholder: t('Last Name'),
              }}
              error={errors.surname}
            />
          </div>
        </div>
        <div className='flex flex-col space-y-4 md:grid md:grid-cols-2 md:space-y-0 md:gap-2 mb-3'>
          <div>
            <p className='font-bold'>Address 1</p>
            <Input
              name='address1'
              type='text'
              placeholder={t('Address 1')}
              register={{
                ...register('address1'),
                defaultValue: profileData.address1,
                placeholder: t('Address 1'),
              }}
            />
          </div>
          <div>
            <p className='font-bold'>Address 2</p>
            <Input
              name='address2'
              type='text'
              placeholder={t('Address 2')}
              register={{
                ...register('address2'),
                defaultValue: profileData.address2,
                placeholder: t('Address 2'),
              }}
            />
          </div>
        </div>
        <div className='flex flex-col space-y-4 md:grid md:grid-cols-2 md:space-y-0 md:gap-2 mb-3'>
          <div>
            <p className='font-bold'>Email address</p>
            <Input
              name='email'
              type='email'
              placeholder={t('Email address')}
              register={{
                ...register('email'),
                defaultValue: profileData.email,
                placeholder: t('Email Address'),
              }}
              defaultValue={profileData?.email}
              error={errors.email}
              disabled
            />
          </div>
          <div>
            <p className='font-bold'>Phone Number</p>
            <Input
              name='phone'
              type='number'
              placeholder={t('+0 (000) 000-000')}
              register={register('phone')}
              defaultValue={profileData?.phone}
            />
          </div>
        </div>
        <div className='flex flex-col space-y-4 md:grid md:grid-cols-2 md:space-y-0 md:gap-2 mb-3'>
          <div>
            <p className='font-bold'>Country</p>
            <Input
              name='country'
              type='text'
              placeholder={t('Country')}
              register={{
                ...register('country'),
                defaultValue: profileData.country,
                placeholder: t('Country'),
              }}
            />
          </div>
          <div>
            <p className='font-bold'>City</p>
            <Input
              name='city'
              type='text'
              placeholder={t('City')}
              register={{
                ...register('city'),
                defaultValue: profileData.city,
                placeholder: t('City'),
              }}
            />
          </div>
        </div>
        <div className='mb-3'>
          <Checkbox
            name='agreement'
            label='I agree to receive email updates'
            register={{
              ...register('subscription'),
              defaultChecked: profileData.subscription,
            }}
            labelClassName='dark:text-white'
          />
        </div>
        {isDirty && (
          <div className='mb-3'>
            <div className='w-[30%]'>
              <div className='flex justify-center'>
                <button
                  className="flex justify-center  w-full items-center go-btn text-white p-2.5 rounded-[30px]"
                  type='submit'
                >
                  <p className='text-[16px]'>{t('Save')}</p>
                </button>
              </div>
            </div>
          </div>
        )}
        <div className='relative flex py-5 items-center'>
          <div className='flex-grow border-t border-gray-400'></div>
          <span className='flex-shrink mx-4 text-gray-400'>Other Options</span>
          <div className='flex-grow border-t border-gray-400'></div>
        </div>
      </form>
      }
      <div className='mb-3 sm:flex inline-block justify-between w-full'>
        <div className='sm:w-[30%] w-full pb-1'>
          <div className='flex justify-center'>
            <button
              className="flex justify-center  w-full items-center go-btn text-white p-2.5 rounded-[30px]"
              onClick={() => {
                openResetModal();
              }}
            >
              <p className='text-[16px]'>{t('Change Password')}</p>
            </button>
          </div>
        </div>
        <div className='sm:w-[30%] w-full pb-1'>
          <div className='flex justify-center'>
            <button
              className="flex justify-center  w-full items-center go-btn text-white p-2.5 rounded-[30px]"
              onClick={() =>
                setModalState({
                  action: 'delete',
                  type: 'question',
                  isOpen: true,
                  message: TEXT_CONTENTS.question.account,
                })
              }
            >
              <p className='text-[16px]'>{t('Delete my account')}</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileForm;