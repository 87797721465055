'use client';

import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props= {
  selected: string,
  target: string,
  icon: IconDefinition
  children: any
}
const TabBtn = ({ children, selected, target, icon } : Props) => {
  return (
    <div className={(selected == target ? 'border-b-4 text-btcs-pink border-b-btcs-pink dark:border-b-btcs-pink' : '') + '  dark:border-white border-black  cursor-pointer inline-block'}>
      <FontAwesomeIcon icon={icon} className="w-[1rem] lg:w-[1.3rem] fa-solid inline-block pr-2" />
      {children}
    </div >
  );
};

export default TabBtn;