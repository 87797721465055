import { createSlice } from '@reduxjs/toolkit';

import { IProfileData } from '@/types/profile';

const PREFIX = 'user';

type ProfileState = {
  data: IProfileData | null;
  hideFractionBalance: boolean;
};

const initialState: ProfileState = {
  data: null,
  hideFractionBalance: false,
};

export const profileSlice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {
    updateProfileUpdated: (state, { payload }) => {
      if (state.data) state.data = { ...state.data, last_sync_time: payload };
    },
    updateFractionBalanceVisible: (state, { payload }) => {
      state.hideFractionBalance = payload;
    },
  },
});

export const { updateProfileUpdated, updateFractionBalanceVisible } = profileSlice.actions;

export default profileSlice.reducer;
