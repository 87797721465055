import axios from '@/services/configs';

export const search = async (query: string) => {
  let url = '/txs?' + query;
  return axios.get(url);
};

export const getCount = async (search: string) => {
  let url = '/txs/count?' + search;
  return axios.get(url);
};

export const saveSearch = async (data: any) => {
  let url = '/searches';
  return axios.post(url, data);
};

export const getSavedSearch = async () => {
  return axios.get('/searches');
};

export const deleteSearch = async (search_id: string)  => {
  return axios.delete(`/searches/${search_id}`);
};
