'use client';

import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function No() {
  return (
    <>
      <FontAwesomeIcon icon={faXmark} className="fa-solid text-red-500"/>&nbsp;
      <span>NO</span>
    </>
  );
}
