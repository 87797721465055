'use client';

import React, { MouseEvent, useCallback } from 'react';

import classNames from 'classnames';

type Props = {
  children: React.ReactNode;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  disabled?: boolean;
};

const IconButton = ({
  children: icon,
  onClick,
  type = 'button',
  className = '',
  disabled = false,
}: Props) => {
  const clickHandler = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (disabled) {
        e.stopPropagation();
        e.preventDefault();
      } else {
        if (onClick) {
          onClick(e);
        }
      }
    },
    [onClick, disabled]
  );

  return (
    <button
      onClick={clickHandler}
      type={type}
      className={classNames(className, { ' p-2': !className.includes('p-') }, ' inline-flex items-center text-center rounded-full hover:bg-opacity-5 hover:bg-dark focus:outline-none')}
    >
      {icon}
    </button>
  );
};

export default IconButton;
