import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  fetchBaseQuery,
} from '@reduxjs/toolkit/dist/query';

import { generateUniqueID } from '@/helpers';
import { getCookie, setCookie } from '@/helpers/cookiePlugin';
import { REQUEST_API_URL } from '@/services/configs';

const baseUrl = `${REQUEST_API_URL}/`;
const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: async (headers) => {
    const token = await getCookie('cq_token');
    let deviceToken = await getCookie('device');

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    if (!deviceToken) {
      deviceToken = generateUniqueID();
      setCookie('device', deviceToken);
    }
    headers.set('device', deviceToken);
  },
});

const customBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const result = await baseQuery(args, api, extraOptions);
  return result;
};

export default customBaseQuery;
