import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { SearchRecordState } from './types';

const PREFIX = 'records';

const initialState: SearchRecordState = {
  custom_search:'',
  ai_analytics:'',
};

export const recordsSlice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {
    setCustomSearch(state, action: PayloadAction<string>) {
      state.custom_search = action.payload;
    },
    setAISearch(state, action: PayloadAction<string>) {
      state.ai_analytics = action.payload;
    },
  },
});

const {
  setCustomSearch,
  setAISearch,
} = recordsSlice.actions;

export const setCustomSearchRecord = (thunkApi: any, search: string) => {
  thunkApi.dispatch(setCustomSearch(search));
};

export const setAISearchRecord = (thunkApi: any, search: string) => {
  thunkApi.dispatch(setAISearch(search));
};

export default recordsSlice.reducer;
