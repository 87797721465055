'use client';
import React, { useEffect, useState } from 'react';

import { faArrowRightArrowLeft, faMoneyBill, faQuestion, faUser } from '@fortawesome/free-solid-svg-icons';
import { ModalContent, ModalHeader, ModalBody } from '@nextui-org/react';
import { useDispatch } from 'react-redux';

import CommonModal from '@/app/components/CommonModal';
import useMobileView from '@/hooks/useMobileView';
import { getCustomerPortalUrl } from '@/services/stripe';
import { showLoading, hideLoading } from '@/store/loading';
import { Plan } from '@/types/subscription';

import { DataAccess } from './data-access';
import { Faqs } from './Faqs';
import { MobileMenu } from './MobileMenu';
import Profile from './profile';
import { ReturnMenu } from './ReturnMenu';
import { SubMenu } from './Submenu';

type MenuContentType = 'Profile' | 'DataAccess' | 'BillingHistory' | 'Faqs';

interface IAccountModal{
    open: boolean,
    setOpen: any,
    tab: string,
    userPlan: Plan,
}

export default function AccountModal({ open, setOpen, tab, userPlan }: IAccountModal) {
  const dispatch = useDispatch();
  const [menuShow, setMenuShow] = useState<boolean>(false);
  const isMobileView  = useMobileView();
  const [menuContentType, setMenuContentType] = useState<MenuContentType>(tab as MenuContentType);
  const [resetShow, setResetShow] = useState<boolean>(false);

  useEffect(()=> {
    setMenuContentType(tab as MenuContentType);
  }, [tab]);
  
  const onSelect = (value: MenuContentType) => {
    setMenuContentType(value);
    if (value === 'BillingHistory') {
      dispatch(showLoading());
      getCustomerPortalUrl()
        .then(res => {
          window.open(res.data.url);
        })
        .catch((e)=> {
          console.log(e);
        })
        .finally(() => {
          dispatch(hideLoading());
        });
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  const menuItems = [
    {
      label: 'Profile',
      value: 'Profile',
      icon: faUser
    },
    {
      label: 'Data Access',
      value: 'DataAccess',
      icon: faArrowRightArrowLeft
    },
    ...[Plan.PRO, Plan.BUSINESS].includes(userPlan) ?
      [{
        label: 'Billing History',
        value: 'BillingHistory',
        icon: faMoneyBill,
      }] : [],
    {
      label: 'FAQs',
      value: 'Faqs',
      icon: faQuestion,
    },
  ];

  return (
    <>
      <CommonModal
        onESC={onClose}
        size={'5xl'} 
        isOpen={open}
        closeButton={<></>}
        className='top-10 '
        scrollBehavior="inside"
      >
        <ModalContent>
          <ModalHeader>
            <a href='#' className='ml-auto'>
              <div onClick={onClose} className=' text-gray-400 bg-transparent hover:cursor-pointer hover:bg-slate-100 dark:hover:bg-slate-700 rounded-lg w-8 h-8 inline-flex justify-center items-center '>
                <svg className='w-3 h-3' aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 14 14'>
                  <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6'/>
                </svg>
              </div>
            </a>
          </ModalHeader>
          <ModalBody className='max-h-[100vh] overflow-y-auto'>
            <div className='flex md:flex-row flex-col overflow-y-auto'>
              {
                isMobileView ? (
                  <MobileMenu selected={menuContentType} items={menuItems} menuShow={menuShow} setMenuShow={setMenuShow} setMenuContent={setMenuContentType}/>
                ) :
                  resetShow ? <ReturnMenu handleReturn={()=> setResetShow(false)} /> :
                    <SubMenu selected={menuContentType} items={menuItems} setMenuContent={onSelect}/>
              }
              <div className="w-full flex">
                <div className="overflow-auto w-full px-2 min-h-[500px] py-4 border-2 md:ml-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 bg-gray-50 dark:bg-gray-800">
                  {menuContentType == 'Profile' && <Profile resetShow={resetShow} setResetShow={setResetShow} onClose={onClose} />}
                  {menuContentType == 'DataAccess' && <DataAccess/>}
                  {menuContentType == 'Faqs' && <Faqs />}
                </div>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </CommonModal>
    </>
  );
}
