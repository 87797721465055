import { createApi } from '@reduxjs/toolkit/query/react';

import customBaseQuery from '@/services/customBaseQuery';
import {
  INotification,
  IProfileData,
  ITutorialState,
  LANDING_PAGES_KEY,
  PasswordType,
} from '@/types/profile';

const PREFIX = 'user';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: customBaseQuery,
  tagTypes: ['UserData'],
  endpoints: (builder) => ({
    getUserData: builder.query<IProfileData, string>({
      query: () => ({
        url: `${PREFIX}/one`,
        method: 'GET',
      }),
      providesTags: ['UserData'],
    }),
    updateUserData: builder.mutation<IProfileData, IProfileData>({
      query: (body) => ({
        url: `${PREFIX}/one`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['UserData'],
    }),
    updateAvatar: builder.mutation<IProfileData, FormData>({
      query: (body) => ({
        url: `${PREFIX}/avatar`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['UserData'],
    }),
    changePassword: builder.mutation<{ success: boolean; message: string }, PasswordType>({
      query: (body) => ({
        url: `${PREFIX}/change`,
        method: 'PUT',
        body,
      }),
    }),
    resetAccount: builder.mutation<Response, string>({
      query: () => ({
        url: `${PREFIX}/reset`,
        method: 'POST',
      }),
    }),
    deleteAccount: builder.mutation<Response, string>({
      query: () => ({
        url: `${PREFIX}/delete`,
        method: 'DELETE',
      }),
    }),
    setNotification: builder.mutation<Response, INotification>({
      query: (body) => ({
        url: `${PREFIX}/notification`,
        method: 'POST',
        body,
      }),
    }),
    logTutorial: builder.mutation<Response, ITutorialState>({
      query: (body) => ({
        url: `${PREFIX}/log_tutorial`,
        method: 'POST',
        body,
      }),
    }),
    updateLandingPageKey: builder.mutation<Response, { name: LANDING_PAGES_KEY }>({
      query: (body) => ({
        url: `${PREFIX}/update_landing`,
        method: 'POST',
        body,
      }),
    }),
    troubleshoot: builder.mutation<Response, string>({
      query: () => ({
        url: `${PREFIX}/log`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetUserDataQuery,
  useUpdateUserDataMutation,
  useUpdateAvatarMutation,
  useChangePasswordMutation,
  useResetAccountMutation,
  useDeleteAccountMutation,
  useTroubleshootMutation,
} = userApi;
