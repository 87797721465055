'use client';

import { IconDefinition, faBars, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { MenuBtn } from './MenuBtn';

type ItemType = {
  label: string;
  value: string;
  icon: IconDefinition;
}
type MobileMenuProps = {
  menuShow: boolean
  setMenuShow: React.Dispatch<React.SetStateAction<boolean>>
  setMenuContent: React.Dispatch<React.SetStateAction<any>>
  items:ItemType[]
  selected: string
}
export const MobileMenu = ({ menuShow, setMenuShow, setMenuContent, items, selected }: MobileMenuProps): React.ReactNode => {
  return (
    <div className="relative h-full px-3 py-4 bg-gray-50 dark:bg-gray-800">
      <div className="flex items-center">
        <FontAwesomeIcon icon={faBars} className="fa-solid lg:w-[1.3rem] w-[2rem] inline-block pr-2" onClick={() => setMenuShow(prev => !prev)}/>
        <div>
          <FontAwesomeIcon icon={faUser} className="fa-solid lg:w-[1.3rem] w-[1rem] inline-block"/>
          <span className="ml-3">{selected}</span>
        </div>
      </div>
      <ul 
        className={`bg-gray-50 dark:bg-gray-800 font-medium absolute z-10 transform ${menuShow?'scale-100':'scale-0'} transition duration-150 ease-in-out origin-top w-[90%]`}
      >
        {items.map((item, idx) => <li key={idx}>
          <MenuBtn label={item.label} onClick={() => {
            setMenuContent(item.value);
            setMenuShow(false);
          }} icon={item.icon} />
        </li>)}
      </ul>
    </div>
  );
};