'use client';

import { useEffect } from 'react';

import { useRouter } from 'next/navigation';
import { useSession } from 'next-auth/react';

import { navigations } from '@/app/constants';

export default function AuthGuard({
  children,
}: {
  children: React.ReactNode
}) {
  const router = useRouter();
  const { data: session } = useSession();
  useEffect(() => {
    if (!session?.user?.isVerified && session?.user?.verificationToken) {
      router.push(`${navigations.CONFIRM_EMAIL}?token=${session?.user?.verificationToken}`);
    }
  }, [session]);

  return (
    <>{children}</>
  );
}
