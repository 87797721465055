'use client';

import { faBackward } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  handleReturn: ()=> void
}

export const ReturnMenu = ({ handleReturn } : Props): React.ReactNode => {
  return (
    <>
      <div className="transition-transform" aria-label="Sidebar">
        <div className="h-full px-3 py-4  bg-gray-50 dark:bg-gray-800 min-w-[15rem]">
          <a href='#' 
            className={`flex items-center p-2 text-gray-900 
                  rounded-lg dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700 group 
                  `}
            onClick={handleReturn}
          >
            <FontAwesomeIcon icon={faBackward} className="fa-solid lg:w-[1.3rem] w-[1rem] inline-block pr-2"/>
            <span className='ml-3'>Go Back to Profile</span>
          </a>
        </div>
      </div>
    </>
  );
};