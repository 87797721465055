'use client';

import { GoogleAnalytics } from '@next/third-parties/google';
import { Poppins } from 'next/font/google';
import { SessionProvider } from 'next-auth/react';

import { AccountModalProvider } from '@/app/contexts/AccountModalContext';
import { FilterModalProvider } from '@/app/contexts/FilterModalContext';

import './globals.css';

import CookieConsent from './components/CookieConsent';
import MainLayout from './components/main_layout';
import { Providers } from './components/Provider';

//🟟 Configure our Poppins object
const poppins = Poppins({
  weight: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
  subsets: ['latin'],
});

export default function RootLayout({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <html lang="en" className={poppins.className}>
      <head>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <title>ChainQ by BTCS - AI Powered Blockchain Analytics</title>
      </head>
      <body suppressHydrationWarning>
        <SessionProvider>
          <Providers>
            <AccountModalProvider>
              <FilterModalProvider>
                <MainLayout component={children}></MainLayout>
              </FilterModalProvider>
            </AccountModalProvider>
          </Providers>
        </SessionProvider>
      </body>
      <GoogleAnalytics gaId="G-GM486E44FY" />
      <CookieConsent />
    </html>
  );
}
