'use client';
import { ModalBody, ModalContent, ModalHeader } from '@nextui-org/react';

import CommonModal from '@/app/components/CommonModal';
import FunctionalBtn from '@/app/components/FunctionalBtn';

type Props = {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
}

export default function AboutModal({ isOpen, setOpen }: Props) {
  
  return (
    <CommonModal onClose={() => setOpen(false)} onESC={() => setOpen(false)} isOpen={isOpen} size='5xl'>
      <ModalContent>
        <ModalHeader>
        </ModalHeader>
        <ModalBody className='mb-20'>
          <div className="lg:flex">
            <div className="px-3 flex items-center">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/dbINSQ7vugc?si=vZpjVrBkF_-oGQdv"
                title="YouTube video player" frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            </div>
            <br/>
            <div className="px-3">
              <p className="text-[1.3rem] font-bold mb-3">What is ChainQ?</p>
              <p>
                ChainQ is an AI-powered blockchain analytics platform, making it easy to analyze the complexities
                of blockchain data. With its intuitive interface and powerful features, including cutting-edge AI,
                personalized search functionality, and insightful visualizations, ChainQ empowers users of all levels
                to explore, organize and understand blockchain data.
              </p>
              <br/>
              <p className="text-[1.3rem] font-bold mb-3">Our Company</p>
              <p>
                BTCS Inc. is a Nasdaq-listed pioneer in the blockchain technology sector since 2014.
                Our mission is to explore the potential of different blockchain technologies bringing solutions to
                end users and delivering value to our shareholders.
              </p>
              <br/>
              <div className="inline-block">
                <a href="https://www.btcs.com/" target="_blank">
                  <FunctionalBtn classes={{ root: 'p-3 px-5', text: 'text-[18px]' }}>Visit BTCS &gt;</FunctionalBtn>
                </a>
              </div>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </CommonModal>
  );
}