'use client';

import { useEffect, useRef, useState } from 'react';

import { faBars, faBookmark, faRightFromBracket, faStar, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type MobileMenuProps = {
  handleBookmarkBtn: ()=> void
  handleMySearchesBtn: ()=> void
  openProfileModal: ()=> void
  onLogout: () => void
}
export const MobileMenu = ({ handleBookmarkBtn, handleMySearchesBtn, openProfileModal, onLogout }: MobileMenuProps): React.ReactNode => {
  const [menuShow, setMenuShow] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement|null>(null);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setMenuShow(false);
      }
    };
    // Attach the event listener when the component mounts
    document.addEventListener('click', handleClickOutside);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div ref={ref}  className="relative h-full py-3 bg-gray-50 dark:bg-gray-800">
      <div className="flex items-center">
        <FontAwesomeIcon icon={faBars} className="fa-solid lg:w-[1.3rem] w-[2rem] inline-block" onClick={() => setMenuShow(prev => !prev)}/>
      </div>
      <ul 
        className={`bg-gray-50 dark:bg-gray-800 right-0 p-3 font-medium absolute z-10 transform ${menuShow?'scale-100':'scale-0'} transition duration-150 ease-in-out origin-top`}
      >
        <li className="flex items-center p-2  hover:bg-gray-100 dark:hover:bg-gray-700" onClick={handleBookmarkBtn}>
          <FontAwesomeIcon icon={faBookmark} className="fa-solid pr-2 w-[1rem]" />
          <span className=' whitespace-nowrap'>My Bookmarks</span>
        </li>
        <li className="flex items-center p-2  hover:bg-gray-100 dark:hover:bg-gray-700" onClick={handleMySearchesBtn}>
          <FontAwesomeIcon icon={faStar} className="fa-solid pr-2 w-[1rem]" />
          <span className=' whitespace-nowrap'>My Searches</span>
          
        </li>
        <li className="flex items-center p-2  hover:bg-gray-100 dark:hover:bg-gray-700" onClick={openProfileModal}>
          <FontAwesomeIcon icon={faUser} className="fa-solid pr-2 w-[1rem]" />
          <span className=' whitespace-nowrap'>My Account</span>
        </li>
        <li className="flex items-center p-2 text-btcs-pink underline  hover:bg-gray-100 dark:hover:bg-gray-700" onClick={onLogout}>
          <FontAwesomeIcon icon={faRightFromBracket} className=" w-[1rem] fa-solid pr-2" />
          <span className=' whitespace-nowrap'>Sign Out</span>
        </li>
      </ul>
    </div>
  );
};