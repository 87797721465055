/* eslint-disable @next/next/no-img-element */
'use client';

import React, { ChangeEventHandler } from 'react';

import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

type AvatarType = {
    onChange: ChangeEventHandler;
    onDelete: () => void;
    src: string | ArrayBuffer | null;
    name: string;
    className: string;
}

function Avatar({ onChange, onDelete, src, name, className }: AvatarType) {
  return (
    <div
      className={
        classNames(
          'md:w-40 w-24 md:h-40 relative mx-auto',
          className
        )}
    >
      {!!src ? (
        <img
          src={src as string}
          alt="User"
          className="border rounded-full shadow-lg border-gray-d1"
        />
      ) : (
        <div className="flex items-center justify-center w-full h-full font-bold border rounded-full shadow-lg border-gray-d1 text-pink md:text-3xl">
          {name}
        </div>
      )}
      {!!src && (
        <label
          onClick={onDelete}
          className="absolute md:left-2.5 md:bottom-2.5 left-1 bottom-1 md:w-8 w-6 md:h-8 h-6 p-1.5 cursor-pointer rounded-full bg-white text-white shadow-lg"
        >
          <TrashIcon className="text-red-500" />
        </label>
      )}
      <label className="absolute md:right-2.5 md:bottom-2.5 right-1 bottom-1 md:w-8 w-6 md:h-8 h-6 p-1.5 cursor-pointer rounded-full bg-white text-gray-600 shadow-lg">
        <PencilIcon />
        <input
          type="file"
          name="userAvatar"
          accept="image/*"
          className="hidden"
          onChange={onChange}
        />
      </label>
    </div>
  );
}

export default Avatar;