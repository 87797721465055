'use client';
import React, { forwardRef, useEffect, useState } from 'react';

import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { FieldError } from 'react-hook-form';

import { keyboardKeys } from '@/app/constants';

type Props = {
  label?: string;
  name: string;
  type: string;
  autoFocus?: boolean;
  maxLength?: number;
  className?: string;
  required?: boolean;
  autoComplete?: string;
  placeholder?: string;
  defaultValue?: string;
  register: any;
  error?: FieldError;
  disabled?: boolean;
  inputClassName?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

// eslint-disable-next-line react/display-name
const Input = forwardRef(
  (
    {
      className = '',
      name,
      autoFocus = false,
      type,
      maxLength,
      required = false,
      placeholder = '',
      defaultValue = '',
      label,
      autoComplete,
      register = {},
      error,
      disabled = false,
      inputClassName = 'dark:bg-dark-light',
      onChange,
    }: Props,
    ref
  ) => {
    const [length, setLength] = useState(0);
    const [isShowed, setIsShowed] = useState(false);
    const [currentType, setCurrentType] = useState(type);

    useEffect(() => {
      if (type === 'password') {
        setCurrentType(isShowed ? 'text' : 'password');
      }
    }, [isShowed, type]);

    const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value;
      setLength(() => value.length);
      if (!value.trim()) {
        if (e.code === keyboardKeys.space) {
          e.preventDefault();
        }
      }
    };

    return (
      <div className={classNames(className, 'relative w-full')}>
        {label ? (
          <label
            htmlFor={name}
            className='block mb-1 text-sm font-bold text-black dark:text-white'
          >
            {label}
          </label>
        ) : null}
        <div className='relative flex items-center'>
          <input
            ref={ref}
            tabIndex={0}
            id={name}
            name={name}
            autoFocus={autoFocus}
            type={currentType}
            autoComplete={autoComplete}
            required={required}
            placeholder={placeholder}
            defaultValue={defaultValue}
            maxLength={maxLength}
            className={classNames(
              inputClassName,
              'appearance-none w-full pl-3  border bg-white border-gray-300 dark:border-transparent rounded-md placeholder-gray-400 outline-none focus:outline-none focus:ring-0 focus:border-gray-400 md:text-sm dark:text-white transition-all duration-500',
              { 'bg-gray-100 text-gray-400': disabled },
              { 'md:py-2.5 py-1.5': !inputClassName.includes('py-' || 'px-') },
              type === 'password' ? 'pr-9' : 'pr-3'
            )}
            onKeyUp={keyDownHandler}
            onChange={onChange}
            disabled={disabled}
            {...register}
          />
          {type === 'password' ? (
            !isShowed ? (
              <EyeIcon
                className='absolute right-0 px-2 cursor-pointer w-9 text-dark dark:text-white'
                onClick={() => setIsShowed(!isShowed)}
              />
            ) : (
              <EyeSlashIcon
                className='absolute right-0 px-2 cursor-pointer w-9 text-dark dark:text-white'
                onClick={() => setIsShowed(!isShowed)}
              />
            )
          ) : null}
        </div>
        {maxLength ? (
          <div className='mt-1 text-sm text-right text-gray-400'>
            {maxLength - length}/{maxLength}
          </div>
        ) : null}
        {error ? (
          <div className='flex-1'>
            <p className='mt-1 text-xs text-red-500'>{error.message}</p>
          </div>
        ) : null}
      </div>
    );
  }
);

export default Input;
