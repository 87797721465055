'use client';
import classNames from 'classnames';
import { useTheme } from 'next-themes';

import './styles.css';

export const Loader = ({
  showLogo = false,
  text = '',
  loaderImage = '',
  global = true,
}: {
  showLogo?: boolean;
  text?: string;
  loaderImage?: string;
  global?: boolean;
}) => {
  const { theme } = useTheme();
  return (
    <div
      className={classNames(
        'left-0 top-0 bg-opacity-80 bg-white dark:bg-black dark:bg-opacity-80 flex justify-center items-center flex-col',
        {
          'w-screen h-screen fixed': global,
          'w-full h-full absolute': !global,
        }
      )}
      style={{ zIndex: 1000 }}
    >
      {showLogo && (
        <div className='md:mx-auto md:w-full pb-11 md:max-w-md'>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img src={ theme === 'dark'? '/chainq-beta-white.png' : '/chainq-beta.png'} className='w-64 h-16 mx-auto md:hidden' alt="logo" />
        </div>
      )}
      {loaderImage && (
        // eslint-disable-next-line @next/next/no-img-element
        <img
          alt=''
          src={loaderImage}
          className='absolute w-auto h-12 mx-auto my-auto'
          style={{ top: `calc(${global ? '50vh' : '50%'} + ${showLogo ? -10 : -56}px)` }}
        />
      )}
      <div className='w-24 h-24 ease-linear border-4 border-t-4 border-gray-200 rounded-full loader' />
      <div
        className='mx-auto mt-10 text-lg font-semibold dark:text-white'
        style={{ minHeight: '28px' }}
      >
        {text ? text : ''}
      </div>
    </div>
  );
};
