'use client';

import React from 'react';

import { Accordion, AccordionItem } from '@nextui-org/accordion';

import { QAs } from './constants';

export function Faqs(){
  return (
    <Accordion variant="splitted" defaultExpandedKeys={['0']}>
      {QAs.map((qa, i) => (
        <AccordionItem
          key={i}
          isCompact={true}
          classNames={{
            base: '!bg-transparent !shadow-none mt-3',
            heading: 'go-btn rounded-full px-5 !whitespace-normal',
            content: 'px-5',
            title: 'text-white',
          }}
          aria-label={qa.q}
          title={qa.q}
        >
          <p className="whitespace-break-spaces">{qa.a}</p>
        </AccordionItem>
      ))}
    </Accordion>
  );
}
